
interface FileData {
  url: string | null;
  name: string | null;
  id: string | null;
  file: File | null;
}
import { ref, PropType, defineComponent, onMounted } from 'vue';
import { documentService } from '@/services/DocumentService';
import { Document, EntityType } from '@/models/Document';
import { Note } from '@/models/Note';
import { ServiceError } from '@/services/util/ServiceError';
import { onAllTypeFileInputChange, isFileBeingCropped } from '@/helpers/composables/useFileInputCrop';
import HeadingComponent from '../HeadingComponent.vue';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: { HeadingComponent },
  props: {
    patientId: {
      type: String,
      required: true,
    },
    documents: {
      type: Array as PropType<Document[]>,
      required: false,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    newNote: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const loadFiles = ref<FileData[]>([]);
    const filesToDelete = ref<any[]>([]);
    const toast = useToast();
    const { t } = useI18n();
    onMounted(async () => {
      if (!props.documents) {
        return;
      }

      await initFilesLoaded();
    });

    const fileInputRef = ref();
    const openFileInput = () => {
      fileInputRef.value?.click();
    };

    const onFileSelect = async (event: any) => {
      const uploadFile = await onAllTypeFileInputChange(event).catch((error) => {
        handleError(error);
      });
      if (uploadFile) {
        loadFiles.value = [...loadFiles.value, uploadFile];
      }
    };

    const uploadFiles = async (createdNote: Note) => {
      const filesToUpload = loadFiles.value;

      const shouldUploadFile = (newFile: FileData) => !newFile.id && newFile.file;

      const processFile = async (newFile: FileData) => {
        const newDocument = createNewDocument(newFile);
        const documentCreated = await createDocument(createdNote, newDocument);
        const s3Url = await generateS3Url(createdNote, documentCreated);

        await uploadToS3(s3Url, newFile);
      };

      if (shouldProcessFiles(filesToUpload, createdNote)) {
        for (const newFile of filesToUpload) {
          try {
            if (shouldUploadFile(newFile)) {
              await processFile(newFile);
            }
          } catch (error) {
            handleError(error);
          }
        }
      }

      deleteFiles(createdNote);
    };

    const shouldProcessFiles = (filesToUpload: FileData[], createdNote: Note) => {
      return filesToUpload?.length > 0 && createdNote?.id;
    };

    const createNewDocument = (newFile: FileData) =>
      ({
        name: newFile.name,
        contentType: newFile.file?.type,
      } as Document);

    const createDocument = async (createdNote: Note, newDocument: Document) => {
      const documentCreated = await documentService.createDocument(
        createdNote.patientId,
        EntityType.NOTE,
        createdNote.id,
        newDocument,
      );

      if (documentCreated instanceof ServiceError) {
        throw new Error('ERR_UPLOAD_FILE');
      }

      return documentCreated;
    };

    const generateS3Url = async (createdNote: Note, documentCreated: Document) => {
      const s3Url = await documentService.generateURLToUploadToS3(
        createdNote.patientId,
        EntityType.NOTE,
        createdNote.id,
        documentCreated.id,
      );

      if (s3Url instanceof ServiceError) {
        throw new Error('ERR_UPLOAD_FILE');
      }

      return s3Url;
    };

    const uploadToS3 = async (s3Url: any, newFile: FileData) => {
      if (!newFile.file || !s3Url.url) {
        return;
      }
      const s3Document = await documentService.uploadToS3(s3Url.url, newFile.file);

      if (s3Document instanceof ServiceError) {
        throw new Error('ERR_UPLOAD_FILE');
      }
    };

    const handleError = (error: any) => {
      switch (error.message) {
        case 'ERR_UPLOAD_FILE':
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorUploadFile')}`,
            life: 3000,
          });
          break;
        case 'ERR_SIZE_FILE':
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorLimitFileSize')}`,
            life: 3000,
          });
          break;
        default:
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorUploadFile')} ${t('messages.notifications.tryLater')}`,
            life: 3000,
          });
          break;
      }
    };

    const deleteFiles = async (note: Note) => {
      if (!note) {
        return;
      }

      for (const file of filesToDelete.value) {
        await documentService.deleteDocument(note.patientId, EntityType.NOTE, note.id, file);
      }
    };

    const initFilesLoaded = async () => {
      if (!props.documents) {
        return;
      }

      loadFiles.value = [];

      for (const document of props.documents) {
        const fileContent = await getFileContentUrlFromS3(document);
        if (!(fileContent instanceof ServiceError)) {
          loadFiles.value.push({
            id: document.id,
            name: document.name,
            url: fileContent,
            file: null,
          });
        }
      }
    };

    const getFileContentUrlFromS3 = async (document: Document) => {
      const response = await documentService.getContentURLFromS3(
        props.patientId,
        document.entityType,
        document.entityId,
        document.id,
      );
      if (!(response instanceof ServiceError)) {
        return response.url;
      }
      return '';
    };

    const onDownloadFileContent = async (fileName: string, fileUrlToDownload: string) => {
      if (!fileUrlToDownload) {
        return;
      }

      const link = document.createElement('a');
      link.href = fileUrlToDownload;
      link.target = '_blank';
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    function deleteLoadedImage(index: number) {
      if (loadFiles.value[index].id) {
        filesToDelete.value.push(loadFiles.value[index].id);
      }
      loadFiles.value.splice(index, 1);
    }

    function resetLoadedFiles() {
      loadFiles.value = loadFiles.value.filter((file) => file.id);
    }

    return {
      loadFiles,
      fileInputRef,
      openFileInput,
      onFileSelect,
      uploadFiles,
      onDownloadFileContent,
      deleteLoadedImage,
      isFileBeingCropped,
      resetLoadedFiles,
    };
  },
});
