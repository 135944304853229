import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0c0ad7c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "p-grid" }
const _hoisted_4 = { class: "p-field p-col-12 p-fluid" }
const _hoisted_5 = { for: "title" }
const _hoisted_6 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_7 = { class: "p-field p-col-12 p-fluid" }
const _hoisted_8 = { for: "message" }
const _hoisted_9 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_10 = { class: "card-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_HealzInput = _resolveComponent("HealzInput")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_NoteFilesCard = _resolveComponent("NoteFilesCard")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeadingComponent, {
      title: _ctx.$t('notes.create-note'),
      divider: false,
      "sub-heading": true
    }, null, 8, ["title"]),
    (_ctx.noteFormDto)
      ? (_openBlock(), _createElementBlock("form", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('notes.title')), 1),
              _createVNode(_component_HealzInput, {
                id: "title",
                modelValue: _ctx.noteFormDto.title,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.noteFormDto.title) = $event)),
                editable: true,
                type: "text",
                class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validateNoteForm.title.valid })
              }, null, 8, ["modelValue", "class"]),
              (_ctx.showValidation && !_ctx.validateNoteForm.title.valid)
                ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.$t(`${_ctx.validateNoteForm.title.message}`)), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('notes.message')), 1),
              _createVNode(_component_Textarea, {
                id: "message",
                modelValue: _ctx.noteFormDto.message,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.noteFormDto.message) = $event)),
                class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validateNoteForm.message.valid }),
                rows: "5",
                "auto-resize": true,
                type: "text"
              }, null, 8, ["modelValue", "class"]),
              (_ctx.showValidation && !_ctx.validateNoteForm.message.valid)
                ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.$t(`${_ctx.validateNoteForm.message.message}`)), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_NoteFilesCard, {
      ref: "noteFilesCardRef",
      patientId: _ctx.patientId,
      "new-note": true
    }, null, 8, ["patientId"]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_Button, {
        class: "p-button-secondary p-button-medium",
        label: _ctx.$t('common.cancel'),
        onClick: _ctx.onCancelEdition
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_Button, {
        class: "p-button p-button-info p-button-medium",
        loading: _ctx.waitSubmit,
        label: _ctx.$t('common.save'),
        onClick: _ctx.onSubmit
      }, null, 8, ["loading", "label", "onClick"])
    ])
  ]))
}