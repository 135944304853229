enum EntityType {
  EVENT = 'EVENT',
  NOTE = 'NOTE',
}

interface Document {
  contentType: string;
  creationDate?: string;
  deleted: boolean;
  entityId: string;
  entityType: EntityType;
  id: string;
  name: string;
  patientId?: string;
  s3Id: string;
}

export { Document, EntityType };
